import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './loginSlice'
import dataReducer from './dataSlice'
import storage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/es/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

const rootPersistConfig = {
    key: 'root',
    storage
}

const userPersistConfig = {
  key: 'user',
  storage: sessionStorage
}

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    data: dataReducer
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store =  configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)