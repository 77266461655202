import { useEffect, Fragment} from "react"
import { useSelector, useDispatch } from 'react-redux';
import { addData } from "../../utils/redux/dataSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { isEqual } from "lodash";

import Cards from "../visualizations/Cards";
import { UnpaidARChart, FFAgingChart, FFBvsAChart } from '../visualizations/charts/PieCharts';
// import { StaticWIPContext } from "../../pages/reports/wip/WIPreports";

import "../../style/static-ar-card.css";

export function StaticMetric(props) {
    const dispatch = useDispatch();
    const arData = useSelector(state => state.data.data[props.objKey]);
    // console.log(arData);

    useEffect(() => {
        // console.log("Data Endpoint: ",props.query);
        fetch(props.query, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: "x6suUdh182QnOhTu00t15kD+iMNzj0pDSMOILUksOW+KCRCQxItkMaSxnMBQ/fDs0r0s92HPnZrxgn+rXgsmbW64PPlvB19QzGBnJ0fVUhqEFDYSh7dP69eYzGr+U79+7X+R0+UbqcK8rHF78nb/5PEJIPV143dhSabj72MW6K2RlC23W1MLdI+Au6GWjkm8ASSwv/47Ek6M0Rtfc3ZHRehAN4ANLrGZmaoGq9DSVgIItBHnMtJCiiJUe1idWLdM+Sw4y8tq8zsIe9UZX1xDYQOwKQO7PBSmJ6GGfSJVI1w01wdJm37BZ25j40NMZNJU/4AZVuTMYbtcP/doxAemPw=="
            },
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            // console.log(data)
            if (!isEqual(data[0], arData)) {
                dispatch(addData({objKey: props.objKey, data: data[0]}))
            }
        })
        .catch(err => {
            console.error(err)
        })
    }, [props, dispatch, arData])

    let label = "AR";
        let maintype = "UNPAID_INVOICE";
        switch (props.objKey.slice(0,3)) {
            case "arDeptStatic": 
                label = "AR";
                break;
            case "arRespStatic":
                label = "AR";
                break;
            // case "wipRespStatic":
            //     label = "WIP";
            //     maintype = "OUTSTANDING_WIP";
            //     break;
            // case "wipDeptStatic":
            //     label = "WIP";
            //     maintype = "OUTSTANDING_WIP";
            //     break;
            case "wip":
                label = "WIP";
                maintype = "OUTSTANDING_WIP";
                break;
            default:
                label = "AR";
        }
    // console.log(arData)
    try {
    if (arData || arData[maintype]) {
        return (
            <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
                {/* <h5>{props.header}</h5> */}
                <div id="data-area">
                    <div id="dollar-metrics">
                        <h6>Outstanding{` ${label}`}</h6>
                        {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                        <h3 className="lrg-dollar-amount">{"$"+arData[maintype].toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                    </div>
                    <div id="static-ar-chart">
                        <UnpaidARChart chartData={arData} centerLabel={props.header}/>
                    </div>
                    <div id="static-ar-cards">
                        {/* <Cards level={props.objKey} metric='UNPAID_INVOICE' /> */}
                        <Cards level={props.objKey} metric='PERCENT_CURR' color='#5C6FC5'/> 
                        <Cards level={props.objKey} metric='PERCENT_31_60' color='#95CB77'/>
                        <Cards level={props.objKey} metric='PERCENT_61_90' color='#F4CA5E'/>
                        <Cards level={props.objKey} metric='PERCENT_OVERDUE' color='#E66B68'/>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <ClipLoader loading={arData} />
        )
    }
} catch (error) {
    console.error(error);
    return (
        <ClipLoader loading={arData} />
    )
}
}

export function StaticFF(props) {

    try {
    if (props.assignedData) {

        const AGING_GROUPS = Object.groupBy(props.assignedData, ({PROJECT_AGING}) => {
			let aging_label = ''
			switch (PROJECT_AGING) {
				case 'Current':
					aging_label = 'DAYS_0_15'
					break;
				case '16-30 Days':
					aging_label = 'DAYS_16_30'
					break;
				case '31+ Days':
					aging_label = 'DAYS_31_PLUS'
					break;
				// case '61-90 Days':
				// 	aging_label = 'DAYS_61_90'
				// 	break;
				// case '91-120 Days':
				// 	aging_label = 'DAYS_91_120'
				// 	break;
				default:
					aging_label = PROJECT_AGING.toUpperCase()
			}

			return aging_label
		})

		// const TOTAL = chartData.chartData.length
		const DAYS_0_15 = 'DAYS_0_15' in AGING_GROUPS ? AGING_GROUPS.DAYS_0_15.length : 0
		const DAYS_16_30 = 'DAYS_16_30' in AGING_GROUPS ? AGING_GROUPS.DAYS_16_30.length : 0
		const DAYS_31_PLUS = 'DAYS_31_PLUS' in AGING_GROUPS ? AGING_GROUPS.DAYS_31_PLUS.length : 0
		// const DAYS_31_60 = 'DAYS_31_60' in AGING_GROUPS? AGING_GROUPS.DAYS_31_60.length : 0
		// const DAYS_61_90 = 'DAYS_61_90' in AGING_GROUPS ? AGING_GROUPS.DAYS_61_90.length : 0
		// const DAYS_91_120 = 'DAYS_91_120' in AGING_GROUPS ? AGING_GROUPS.DAYS_91_120.length : 0
		// const OVERDUE = 'OVERDUE' in AGING_GROUPS ? AGING_GROUPS.OVERDUE.length : 0
		const EXTENDED = 'EXTENDED' in AGING_GROUPS ? AGING_GROUPS.EXTENDED.length : 0

        return (
            <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
                <h5>Aging Status</h5>
                <div id="data-area">
                    {/* <div id="dollar-metrics">
                        <h6>Outstanding{` ${label}`}</h6>
                        <h3 className="lrg-dollar-amount">{"$"+arData[maintype].toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                    </div> */}
                    <div id="static-ar-chart">
                        <FFAgingChart chartData={props.assignedData} filter={props.filter} setFilter={props.setFilter} />
                    </div>
                    <div id="static-ff-cards">
                        <Cards title='Current' color='#40B27D' value={DAYS_0_15} />
                        <Cards title='16-30 Days' color='#F4CA5E' value={DAYS_16_30}/>
                        <Cards title='31+ Days' color='#FC8451' value={DAYS_31_PLUS}/>
                        {/* {DAYS_31_60 > 0 ? <Cards title='31-60 Days' color='#F4CA5E' value={DAYS_31_60} /> : <Fragment></Fragment>}
                        {DAYS_61_90 > 0 ? <Cards title='61-90 Days' color='#5C7BD9' value={DAYS_61_90}/> : <Fragment></Fragment>}
                        {DAYS_91_120 > 0 ? <Cards title='91-120 Days' color='#FC8451' value={DAYS_91_120}/> : <Fragment></Fragment>}
                        {OVERDUE > 0 ? <Cards title='Overdue' color='#E66B68' value={OVERDUE} /> : <Fragment></Fragment>}  */}
                        <Cards title='Extended' color='#7ED2F4' value={EXTENDED} />
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <ClipLoader loading={props.assignedData} />
        )
    }
} catch (error) {
    console.error(error);
    return (
        <ClipLoader loading={props.assignedData} />
    )
}
}

export function BvsAFF(props) {
    const userLevel = useSelector(state => state.user.LEVEL);

    try {
    if (props.assignedData) {

        let data = props.assignedData

        let checkColumns = ['TAX_PREP_HOURS', 'TAX_REVIEW_HOURS', 'CAAS_PREP_HOURS', 'CAAS_REVIEW_HOURS', 'CAAS_ACCT_HOURS']
        let checkColumnObj = {}

        checkColumns.forEach(col => {
            checkColumnObj[col] = data.flat().reduce((total, obj) => obj[col] + total, 0)
        })

        if (userLevel !== 'SUPERVISOR' && userLevel !== 'SENIOR') {
            data = data.map(({TAX_REVIEW_BVSA, CAAS_REVIEW_BVSA, CAAS_ACCT_BVSA, ...data}) => data)
        }
    
        if (checkColumnObj['CAAS_ACCT_HOURS'] === 0) {
            data = data.map(({CAAS_ACCT_BVSA, ...data}) => data)
        }
        
        if (checkColumnObj['CAAS_PREP_HOURS'] === 0) {
            data = data.map(({CAAS_PREP_BVSA, ...data}) => data)
        }
    
        if (checkColumnObj['CAAS_REVIEW_HOURS'] === 0) {
            data = data.map(({CAAS_REVIEW_BVSA, ...data}) => data)
        }
    
        if (checkColumnObj['TAX_PREP_HOURS'] === 0) {
            data = data.map(({TAX_PREP_BVSA, ...data}) => data)
        }
    
        if (checkColumnObj['TAX_REVIEW_HOURS'] === 0) {
            data = data.map(({TAX_REVIEW_BVSA, ...data}) => data)
        }

        const BUDGET_GROUPINGS = ['TAX_PREP_BVSA', 'TAX_REVIEW_BVSA', 'CAAS_ACCT_BVSA', 'CAAS_PREP_BVSA', 'CAAS_REVIEW_BVSA']

        let budgetObj = {red: [], yellow: [], green: []}

        BUDGET_GROUPINGS.forEach(grouping => {
            budgetObj.red = [...budgetObj.red, ...data.filter(o => o[grouping] === 'RED')]
            budgetObj.yellow = [...budgetObj.yellow, ...data.filter(o => o[grouping] === 'YELLOW')]
            budgetObj.green = [...budgetObj.green, ...data.filter(o => o[grouping] === 'GREEN')]
        })

        budgetObj.red = new Set(budgetObj.red)
        budgetObj.green = new Set(budgetObj.green)
        budgetObj.yellow = new Set(budgetObj.yellow)

        return (
            <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
                <h5>Budget vs Actual</h5>
                <div id="data-area">
                    {/* <div id="dollar-metrics">
                        <h6>Outstanding{` ${label}`}</h6>
                        <h3 className="lrg-dollar-amount">{"$"+arData[maintype].toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                    </div> */}
                    <div id="static-ar-chart">
                        <FFBvsAChart chartData={budgetObj} filter={props.filter} setFilter={props.setFilter} />
                    </div>
                    <div id="static-ff-cards">
                        <Cards title='Under Budget' color='#006100' value={budgetObj.green.size} />
                        <Cards title='75% Complete' color='#ffeb9c' value={budgetObj.yellow.size}/>
                        <Cards title='Over Budget' color='#9c0006' value={budgetObj.red.size} />
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <ClipLoader loading={props.assignedData} />
        )
    }
} catch (error) {
    console.error(error);
    return (
        <ClipLoader loading={props.assignedData} />
    )
}
}

export function StaticValues(dataTable, type) {
    const ComputPercentages = (values) => {
        let shape = {
            outstanding:values.outstanding, 
            PERCENT_31_60: (values["31-60 Days"] / numberOfEntries) * 100, 
            PERCENT_61_90: (values["61-90 Days"] / numberOfEntries) * 100, 
            PERCENT_OVERDUE: (values["Overdue WIP"] / numberOfEntries) * 100,  
            PERCENT_CURR: (values["Current"] / numberOfEntries) * 100, 
        }
        return shape
    }
    
    let outstanding;
    let numberOfEntries;
    if (dataTable !== null) {
        numberOfEntries = dataTable.length;
        outstanding = dataTable.reduce((acc, nxt)=>{
        acc.outstanding += nxt[type];
        acc[nxt.AGING_PERIOD] += 1
        return acc;
        }, {outstanding:0, "31-60 Days":0, "61-90 Days":0, "Overdue WIP":0, "Current":0})
        return ComputPercentages(outstanding);
    }

    return outstanding;
}

// function labeler (type) {
//     let label = "AR";
//     let maintype = "UNPAID_INVOICE";
//     switch (type.objKey.slice(0,3)) {
//         case "arDeptStatic": 
//             label = "AR";
//             break;
//         case "arRespStatic":
//             label = "AR";
//             break;
//         case "wip":
//             label = "WIP";
//             break;
//         default:
//             label = "AR";
//     }
// }

export function StaticMetricView(props) {
    // const { staticWIP, setStaticWIP, staticValue } = useContext(StaticWIPContext);
    const outstanding = props.data ? parseFloat(props.data.outstanding) : 0;
    const overdue = props.data ? props.data.PERCENT_OVERDUE: 0;
    const thirty = props.data ? props.data.PERCENT_31_60: 0;
    const sixty = props.data ? props.data.PERCENT_61_90: 0;
    
    return (
        <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
            {/* <h5>{props.header}</h5> */}
            <div id="data-area">
                <div id="dollar-metrics">
                    <h6>Outstanding{` ${props.label}`}</h6>
                    {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                    <h3 className="lrg-dollar-amount">{"$"+outstanding.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                </div>
                <div id="static-ar-chart">
                    <UnpaidARChart chartData={props.data} centerLabel={props.header}/>
                </div>
                <div id="static-ar-cards">
                    {/* <Cards level={props.objKey} metric='UNPAID_INVOICE' /> */}
                    <Cards cardValue={props.objKey} metric='PERCENT_CURR' color='#5C6FC5'/> 
                    <Cards cardValue={thirty} metric='PERCENT_31_60' color='#95CB77'/>
                    <Cards cardValue={sixty} metric='PERCENT_61_90' color='#F4CA5E'/>
                    <Cards cardValue={overdue} metric='PERCENT_OVERDUE' color='#E66B68'/>
                </div>
            </div>
        </section>
    )
}