// import ProfileContent from "../components/ProfileContent"
import { Fragment } from "react"
import { useSelector } from 'react-redux';

// import { Dashboard } from "../components/visualizations/Dashboard.jsx";
import { MyWIP } from './reports/wip/MyWIP';
import MyFF from './reports/firm_flow/MyFF.jsx'

export default function Home() {
	document.title = 'Magnify'
	const employee = useSelector(state => state.user.EMPLOYEE)

	return (
		<Fragment>
			<center>
				<h5 className="card-title primary-bg h-1rem">Welcome, {employee}</h5>
			</center>
			{/* <Dashboard /> */}
			<section id="data-homepage" className="w-full flex flex-wrap primary-bg">
				{/* <div className="w-full h-screen primary-bg"> */}
				<MyWIP classes={'wip-layout'} />
                <MyFF />
			</section>
		</Fragment>
	)
}