import React, { Fragment, useEffect, useState } from 'react';
import { useTeams } from "msteams-react-base-component";
import { app, authentication } from '@microsoft/teams-js';
import { jwtDecode } from 'jwt-decode';

import { PageLayout } from './components/Nav';

import { PrimeReactProvider/*, PrimeReactContext*/ } from 'primereact/api';

// import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primereact/resources/themes/soho-light/theme.css"
import "./style/theme.css"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from './utils/redux/loginSlice';
import { callMsGraph } from './graph';

import ClipLoader from "react-spinners/ClipLoader"
// import RingLoader from "react-spinners/RingLoader"
// import SyncLoader from "react-spinners/SyncLoader"

import './style/App.css';
import Home from './pages/Home';
// import Reports from './pages/reports/Reports';
import { loginRequest } from './authConfig';
// import FirmAR from './pages/reports/ar/FirmAR';
// import DeptAR from './pages/reports/ar/DeptAR';
// import RespAR from './pages/reports/ar/RespAR';
// import { RespWIP, DeptWIP, TeamWIP } from './pages/reports/wip/WIPreports'
// import { MyWIP } from './pages/reports/wip/MyWIP';
// import Member from './pages/reports/powerbi/Member';
// import Morale from './pages/people/Morale';
// import Review from './pages/people/Review';
// import Bingo from './pages/apps/Bingo';
// import NCTO from './pages/apps/NCTO/NCTO';

// import { DataRefresher, db, GetRemoteVersions, UpdateLessChangelog, DataRefreshTimer } from './utils/DataRefresher';

function ActiveUserRoute(props) {
    const authenticated = useSelector(state => state.user.authenticated)

    if (authenticated === true) {
        // if (true === true) {
        return (
            <Router>
                <PageLayout>
                    <Routes>
                        <Route exact path='/' element={<Home inProgress={props.inProgress} />}/>
                        {/* <Route exact path='/reports' element={<Reports />}/>
                        <Route exact path='/reports/ar/firm' element={<FirmAR />} />
                        <Route exact path='/reports/ar/dept' element={<DeptAR />} />
                        <Route exact path='/reports/ar/responsible' element={<RespAR />} />

                        <Route exact path='/reports/wip/responsible' element={<RespWIP />} />
                        <Route exact path='/reports/wip/self' element={<MyWIP />} />
                        <Route exact path='/reports/wip/dept' element={<DeptWIP />} />
                        <Route exact path='/reports/wip/team' element={<TeamWIP />} />

                        <Route exact path='/reports/powerbi/member' element={<Member />} />
                        <Route exact path='/people/morale' element={<Morale />} />
                        <Route exact path='/people/reviews' element={<Review />} />
                        <Route exact path='/apps/ncto' element={<NCTO />} />
                        <Route exact path='/apps/bingo' element={<Bingo />} /> */}
                        
                        {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
                    </Routes>
                </PageLayout>
            </Router>
        )
    } else if (authenticated === 'not in company') {
        return (
            <Fragment>
                <p>Uh oh! Looks like you are not a registered user with this company.</p>
                <button onClick={() => {
                    // dispatch(reset())
                    localStorage.clear()
                    sessionStorage.clear()
                    // props.setEmail(null)
                    window.location.reload()
                }}>Click here to reset</button>
            </Fragment>
        )
    } else {
        return (
            <ClipLoader loading={true} />

        )
    }
}

export default function App() {
    const [email, setEmail] = useState(null);
    const { instance, inProgress } = useMsal();
    const USER = useSelector(state => state.user);
    const authenticated = USER.authenticated;
    const bearer = useSelector(state => state.user.bearer)
    const dispatch = useDispatch()

    const [{ inTeams }] = useTeams()

    // const [dataRefreshingState, setDataRefreshingState] = useState(false);
    // const [dataRefreshTime, setDataRefreshTime] = useState(Date.now())

    useEffect(() => {
        if (inTeams === true && authenticated === false && !email) {
            authentication.getAuthToken({
                resources: ['api://stewart.magnify-app.com/ea4bcb67-563c-48b7-a8f2-cb35a8ea11c5'],
                silent: false
            }).then(token => {
                dispatch(setUser({authenticated: 'pending', bearer: bearer}))
                const decoded = jwtDecode(token)
                setEmail(decoded.preferred_username)
                app.notifySuccess()
            }).catch(message => {
                dispatch(setUser({authenticated: false}))
                app.notifyFailure({
                    reason: app.FailedReason.AuthFailed,
                    message
                })
            })
        } else if (!email && authenticated === false && inProgress === InteractionStatus.None) {
            instance.handleRedirectPromise()
                .then(tokenResponse => {
                    if (tokenResponse) {
                        callMsGraph(tokenResponse.accessToken)
                            .then(() => {
                                setEmail(tokenResponse.account.username)
                                dispatch(setUser({authenticated: 'pending', bearer: bearer}))
                            })
                            // .then(() => {
                            //     let email = tokenResponse.account.username.toUpperCase()

                            //     if (email.includes('ABACUSTECHNOLOGIES.COM')) {
                            //         // email = 'D.JACOBSON@STEWARTCO-CPA.COM'
                            //         email = 'TAYLOR@STEWARTCO-CPA.COM'
                            //     }
                                
                            //     // email = email.split('@')
                            //     // let addressSite = email[1].split('.')
                            //     // const user = email[0]
                            //     // const domain = addressSite[0]
                            //     // const ext = addressSite[1]

                            //     fetch(`https://mag-stewart.azurewebsites.net/api/staff/me`, {
                            //         method: "POST",
                            //         headers: {
                            //             Accept: "application/json",
                            //             "Content-Type": "application/json",
                            //             "Authorization": bearer
                            //         },
                            //         body: JSON.stringify({email: email})
                            //     }).then(res => {
                            //         if (!res.ok) {
                            //             throw new Error('Uh oh! Looks like you are not a registered user with this company')
                            //         } else {
                            //             return res.json()
                            //         }
                            //     }).then(data => {
                            //         dispatch(setUser({authenticated: true, ...data, bearer: bearer}))
                            //         // setUserData(data[0])
                            //     }).catch((err) => {
                            //         console.error(err)
                            //         dispatch(setUser({authenticated: false, message: 'Uh oh! Looks like you are not a registered user with this company!'}))
                            //     })
                            // })
                    } else {
                        throw new Error('No token response!')
                    }
                })
                .catch(err => {console.error(err)})
        }
    }, [inProgress, email, instance, authenticated, dispatch, inTeams, setEmail, bearer])

    useEffect(() => {
        if (authenticated === 'pending' && email) {
            let userEmail = email.toUpperCase()
            if (userEmail.includes('ABACUSTECHNOLOGIES.COM')) {
                userEmail = 'A.HESSING@STEWARTCO-CPA.COM'
            } 
            // else if (userEmail.includes('BMSS.COM')) {
            //     userEmail = 'D.JACOBSON@STEWARTCO-CPA.COM'
            // }

            fetch(`https://mag-stewart.azurewebsites.net/api/staff/me`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": bearer
                    },
                    body: JSON.stringify({email: userEmail})
                }).then(res => {
                    if (!res.ok) {
                        throw new Error('Uh oh! Looks like you are not a registered user with this company')
                    } else {
                        return res.json()
                    }
                }).then(data => {
                    dispatch(setUser({authenticated: true, ...data, bearer: bearer}))
                    // setUserData(data[0])
                }).catch((err) => {
                    console.error(err)
                    dispatch(setUser({authenticated: 'not in company', message: 'Uh oh! Looks like you are not a registered user with this company!'}))
                })
        }
    }, [email, authenticated, bearer, dispatch])

    // useEffect(()=>{
    //     // setDataRefreshingState(true);
    //     GetRemoteVersions({
    //         changeTable: " https://magnify-apis.azurewebsites.net/api/tablechangelog",
    //         tableHeaders: {authorization:"x6suUdh182QnOhTu00t15kD+iMNzj0pDSMOILUksOW+KCRCQxItkMaSxnMBQ/fDs0r0s92HPnZrxgn+rXgsmbW64PPlvB19QzGBnJ0fVUhqEFDYSh7dP69eYzGr+U79+7X+R0+UbqcK8rHF78nb/5PEJIPV143dhSabj72MW6K2RlC23W1MLdI+Au6GWjkm8ASSwv/47Ek6M0Rtfc3ZHRehAN4ANLrGZmaoGq9DSVgIItBHnMtJCiiJUe1idWLdM+Sw4y8tq8zsIe9UZX1xDYQOwKQO7PBSmJ6GGfSJVI1w01wdJm37BZ25j40NMZNJU/4AZVuTMYbtcP/doxAemPw=="},
    //     }).then((resp)=>{
    //     console.log("Value returned from the changlog api", resp);
        
    //     const viewsToLoad = {        
    //                 database: db,
    //                 tableHeaders: {authorization:"x6suUdh182QnOhTu00t15kD+iMNzj0pDSMOILUksOW+KCRCQxItkMaSxnMBQ/fDs0r0s92HPnZrxgn+rXgsmbW64PPlvB19QzGBnJ0fVUhqEFDYSh7dP69eYzGr+U79+7X+R0+UbqcK8rHF78nb/5PEJIPV143dhSabj72MW6K2RlC23W1MLdI+Au6GWjkm8ASSwv/47Ek6M0Rtfc3ZHRehAN4ANLrGZmaoGq9DSVgIItBHnMtJCiiJUe1idWLdM+Sw4y8tq8zsIe9UZX1xDYQOwKQO7PBSmJ6GGfSJVI1w01wdJm37BZ25j40NMZNJU/4AZVuTMYbtcP/doxAemPw=="},
    //                 tables:[
    //                     {table:"UNPAID_AR", endpoint: " https://magnify-apis.azurewebsites.net/api/ar/unpaid/full", remoteversion:resp.UNPAID_AR},
    //                     {table:"OUTSTANDING_WIP", endpoint: " https://magnify-apis.azurewebsites.net/api/wip/outstanding/full", remoteversion:resp.OUTSTANDING_WIP},
    //                     {table:"ENGAGEMENT", endpoint: " https://magnify-apis.azurewebsites.net/api/engagement", remoteversion:resp.ENGAGEMENT},
    //                     {table:"SERVICES", endpoint: " https://magnify-apis.azurewebsites.net/api/services", remoteversion:resp.SERVICES},
    //                     {table:"STAFF", endpoint: " https://magnify-apis.azurewebsites.net/api/staff/all/", remoteversion:resp.STAFF},
    //                     {table:"JOB_TEMPLATES", endpoint: " https://magnify-apis.azurewebsites.net/api/jobs/templates", remoteversion:resp.JOB_TEMPLATES}
    //                 ]
    //             }
    //     for (let i = 0; i < viewsToLoad.tables.length; ++i) {
    //         let refreshedData = DataRefresher(viewsToLoad.tables[i], viewsToLoad.database, viewsToLoad.tableHeaders);
    //         refreshedData.then(()=>{
    //             console.log("Data Refreshed");
    //             if (i === viewsToLoad.tables.length) {setDataRefreshingState(false);};
    //         })
    //     };
    // })
    // }, [])

    // useEffect( () => {
    //     const staticToLoad = {
    //         database: db,
    //         tableHeaders: {authorization:"x6suUdh182QnOhTu00t15kD+iMNzj0pDSMOILUksOW+KCRCQxItkMaSxnMBQ/fDs0r0s92HPnZrxgn+rXgsmbW64PPlvB19QzGBnJ0fVUhqEFDYSh7dP69eYzGr+U79+7X+R0+UbqcK8rHF78nb/5PEJIPV143dhSabj72MW6K2RlC23W1MLdI+Au6GWjkm8ASSwv/47Ek6M0Rtfc3ZHRehAN4ANLrGZmaoGq9DSVgIItBHnMtJCiiJUe1idWLdM+Sw4y8tq8zsIe9UZX1xDYQOwKQO7PBSmJ6GGfSJVI1w01wdJm37BZ25j40NMZNJU/4AZVuTMYbtcP/doxAemPw=="},
    //         tables:[
    //             {table:"STATIC_RESP_AR", endpoint: `https://magnify-apis.azurewebsites.net/api/ar/static/responsible/${USER.STAFFINDEX}`, tableHeaders: {Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key": "wYKvtwhHeXhz3Du7sqBBbVcVfzOP6DUzKJH2MchVZZi-AzFukcWOgQ==",
    //                 "Authorization": "WRqnBICzOclIWWVoPJmGI504O7mxcPHZ/iRCIt0NPtpi4nAPIqoJRNLB21UuBUQrzEjg+oiAWXCbwiFHRa1VyOJKQgufoB9BuVjtCmkUES0wrs3Vt3vhCIb2ZjdsJWnZtqMLPxG0S7j4Ik57i/jX7zEhhOThmvRbKyPNjwOKBOwKA71GHV/mqxmyPF13+v3Q8FWnFSpk9ugv1WOTWqPjaVNz41qXDQmbPqtqrMugzJ6zffWYaVlwjGnATQ9Z5eGTwjgpECJeG658IrmgoGwvHNoJB+zMlbmD52S51wnrVFi0j9Nxz1MKgWHnIVX/i73C7f71gOLxFkgR7l8G/eTkZQ=="}},
    //             {table:"STATIC_DEPT_AR", endpoint: `https://magnify-apis.azurewebsites.net/api/ar/static/dept/${USER.DEPARTMENT}`, tableHeaders: {Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key": "wYKvtwhHeXhz3Du7sqBBbVcVfzOP6DUzKJH2MchVZZi-AzFukcWOgQ==",
    //                 "Authorization": "WRqnBICzOclIWWVoPJmGI504O7mxcPHZ/iRCIt0NPtpi4nAPIqoJRNLB21UuBUQrzEjg+oiAWXCbwiFHRa1VyOJKQgufoB9BuVjtCmkUES0wrs3Vt3vhCIb2ZjdsJWnZtqMLPxG0S7j4Ik57i/jX7zEhhOThmvRbKyPNjwOKBOwKA71GHV/mqxmyPF13+v3Q8FWnFSpk9ugv1WOTWqPjaVNz41qXDQmbPqtqrMugzJ6zffWYaVlwjGnATQ9Z5eGTwjgpECJeG658IrmgoGwvHNoJB+zMlbmD52S51wnrVFi0j9Nxz1MKgWHnIVX/i73C7f71gOLxFkgR7l8G/eTkZQ=="}},
    //             {table:"STATIC_RESP_WIP", endpoint: `https://magnify-apis.azurewebsites.net/api/wip/static/responsible/${USER.STAFFINDEX}`, tableHeaders: {Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key": "hV5RgrOzq2HnYBbMoLjtygM5nSg25WA8PyYwBUC1AqnIAzFudb02cw==",
    //                 "Authorization": "WRqnBICzOclIWWVoPJmGI504O7mxcPHZ/iRCIt0NPtpi4nAPIqoJRNLB21UuBUQrzEjg+oiAWXCbwiFHRa1VyOJKQgufoB9BuVjtCmkUES0wrs3Vt3vhCIb2ZjdsJWnZtqMLPxG0S7j4Ik57i/jX7zEhhOThmvRbKyPNjwOKBOwKA71GHV/mqxmyPF13+v3Q8FWnFSpk9ugv1WOTWqPjaVNz41qXDQmbPqtqrMugzJ6zffWYaVlwjGnATQ9Z5eGTwjgpECJeG658IrmgoGwvHNoJB+zMlbmD52S51wnrVFi0j9Nxz1MKgWHnIVX/i73C7f71gOLxFkgR7l8G/eTkZQ=="}},
    //             {table:"STATIC_DEPT_WIP", endpoint: `https://magnify-apis.azurewebsites.net/api/wip/static/dept/${USER.DEPARTMENT}`, tableHeaders: {Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key": "hV5RgrOzq2HnYBbMoLjtygM5nSg25WA8PyYwBUC1AqnIAzFudb02cw==",
    //                 "Authorization": "WRqnBICzOclIWWVoPJmGI504O7mxcPHZ/iRCIt0NPtpi4nAPIqoJRNLB21UuBUQrzEjg+oiAWXCbwiFHRa1VyOJKQgufoB9BuVjtCmkUES0wrs3Vt3vhCIb2ZjdsJWnZtqMLPxG0S7j4Ik57i/jX7zEhhOThmvRbKyPNjwOKBOwKA71GHV/mqxmyPF13+v3Q8FWnFSpk9ugv1WOTWqPjaVNz41qXDQmbPqtqrMugzJ6zffWYaVlwjGnATQ9Z5eGTwjgpECJeG658IrmgoGwvHNoJB+zMlbmD52S51wnrVFi0j9Nxz1MKgWHnIVX/i73C7f71gOLxFkgR7l8G/eTkZQ=="}},
    //             {table:"STATIC_TEAM_WIP", endpoint: `https://magnify-apis.azurewebsites.net/api/wip/static/team/${USER.HOMEROOM}`, tableHeaders: {Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key": "hV5RgrOzq2HnYBbMoLjtygM5nSg25WA8PyYwBUC1AqnIAzFudb02cw==",
    //                 "Authorization": "WRqnBICzOclIWWVoPJmGI504O7mxcPHZ/iRCIt0NPtpi4nAPIqoJRNLB21UuBUQrzEjg+oiAWXCbwiFHRa1VyOJKQgufoB9BuVjtCmkUES0wrs3Vt3vhCIb2ZjdsJWnZtqMLPxG0S7j4Ik57i/jX7zEhhOThmvRbKyPNjwOKBOwKA71GHV/mqxmyPF13+v3Q8FWnFSpk9ugv1WOTWqPjaVNz41qXDQmbPqtqrMugzJ6zffWYaVlwjGnATQ9Z5eGTwjgpECJeG658IrmgoGwvHNoJB+zMlbmD52S51wnrVFi0j9Nxz1MKgWHnIVX/i73C7f71gOLxFkgR7l8G/eTkZQ=="}}
    //         ]
    //     };

    //     for (let i = 0; i < staticToLoad.tables.length; ++i ) {
    //         let staticData = UpdateLessChangelog(staticToLoad.tables[i], staticToLoad.database, staticToLoad.tables[i].tableHeaders);
    //     }
    // }, [])

    // const override = {
    //     display: "block",
    //     margin: "0 auto",
    //     borderColor: "red",
    //     position: "absolute",
    //     top: "65px",
    //   };

    return (
        <PrimeReactProvider>
            {/* <RingLoader color="Black" loading={dataRefreshingState} cssOverride={override} size={50} aria-label="Refreshing Data" data-testid="data-refresh-loader"/> */}
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
            >
                <ActiveUserRoute inProgress={inProgress} />
            </MsalAuthenticationTemplate>
        </PrimeReactProvider>
    )
}