import { useSelector } from "react-redux"
import { Fragment } from "react";
import { DataViewTable } from "../../../components/visualizations/Tables";
// import { FFChart } from "../../../components/visualizations/charts/PieCharts"
// import { SyncLoader } from "react-spinners";
// import { RingGuage } from "../../../components/visualizations/charts/RingCharts";

// import { Splitter, SplitterPanel } from 'primereact/splitter';
// import { Divider } from 'primereact/divider';
// import { Card } from 'primereact/card';
    
import "primeicons/primeicons.css"
import "/node_modules/primeflex/primeflex.css"
import "../../../style/mywip.css"

export default function FF(props) {

    const userLevel = useSelector(state => state.user.LEVEL);

    const dummyFunc = () => {return 0}
   
    const assignedList = {
        CLIENTNAME: [ ...new Set(props.data.map(item => item.CLIENTNAME))],
        ENGAGEMENTTYPE: [ ...new Set(props.data.map(item => item.ENGAGEMENTTYPE))],
        CURRENTSTEP: [ ...new Set(props.data.map(item => item.CURRENTSTEP))],
        TAX_PREP_HOURS: null,
        TAX_PREP_BUDGET: null,
        TAX_REVIEW_HOURS: null,
        TAX_REVIEWER_BUDGET: null,
        CAAS_ACCT_HOURS: null,
        CAAS_ACCT_BUDGET: null,
        CAAS_PREP_HOURS: null,
        CAAS_PREP_BUDGET: null,
        CAAS_REVIEW_HOURS: null,
        CAAS_REVIEWER_BUDGET: null,
        // DELIVERABLES: [ ...new Set(props.data.map(item => item.DELIVERABLES))],
        PIC: [ ...new Set(props.data.map(item => item.PIC))],
        DAYS_AT_STEP: null,
        // PROJECT_AGING: [ ...new Set(props.data.map(item => item.PROJECT_AGING))],
    }

    // const picList = {
    //     CLIENTNAME: [ ...new Set(props.data.map(item => item.CLIENTNAME))],
    //     CLIENTNUMBER: [ ...new Set(props.data.map(item => item.CLIENTNUMBER))],
    //     ENGAGEMENTTYPE: [ ...new Set(props.data.map(item => item.ENGAGEMENTTYPE))],
    //     DELIVERABLES: [ ...new Set(props.data.map(item => item.DELIVERABLES))],
    //     ASSIGNEDTO: [ ...new Set(props.data.map(item => item.ASSIGNEDTO))],
    //     CURRENTSTEP: [ ...new Set(props.data.map(item => item.CURRENTSTEP))],
    //     WORKFLOW_STATUS: [ ...new Set(props.data.map(item => item.WORKFLOW_STATUS))],
    //     ASSIGNEDON: null,
    //     INTERNALDUEDATE: null,
    //     CURRENTDUEDATE: null,
    //     DAYSATSTEP: null
    // }

    // function filterByValue(array, string) {
    //     return array.filter(o =>
    //         Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
    // }

    let checkColumns = ['TAX_PREP_HOURS', 'TAX_REVIEW_HOURS', 'CAAS_PREP_HOURS', 'CAAS_REVIEW_HOURS', 'CAAS_ACCT_HOURS']
    let checkColumnObj = {}

    let data = props.data

    checkColumns.forEach(col => {
        checkColumnObj[col] = props.data.flat().reduce((total, obj) => obj[col] + total, 0)
    })

    if (userLevel !== 'SUPERVISOR' && userLevel !== 'SENIOR') {
        delete assignedList['TAX_REVIEW_HOURS']
        delete assignedList['TAX_REVIEWER_BUDGET']
        delete assignedList['CAAS_REVIEW_HOURS']
        delete assignedList['CAAS_REVIEWER_BUDGET']
        delete assignedList['CAAS_ACCT_HOURS']
        delete assignedList['CAAS_ACCT_BUDGET']
        data = data.map(({TAX_REVIEW_BVSA, CAAS_REVIEW_BVSA, CAAS_ACCT_BVSA, ...data}) => data)
    }

    if (checkColumnObj['CAAS_ACCT_HOURS'] === 0) {
        delete assignedList['CAAS_ACCT_HOURS']
        delete assignedList['CAAS_ACCT_BUDGET']
        data = data.map(({CAAS_ACCT_BVSA, ...data}) => data)
    }
    
    if (checkColumnObj['CAAS_PREP_HOURS'] === 0) {
        delete assignedList['CAAS_PREP_HOURS']
        delete assignedList['CAAS_PREP_BUDGET']
        data = data.map(({CAAS_PREP_BVSA, ...data}) => data)
    }

    if (checkColumnObj['CAAS_REVIEW_HOURS'] === 0) {
        delete assignedList['CAAS_REVIEW_HOURS']
        delete assignedList['CAAS_REVIEWER_BUDGET']
        data = data.map(({CAAS_REVIEW_BVSA, ...data}) => data)
    }

    if (checkColumnObj['TAX_PREP_HOURS'] === 0) {
        delete assignedList['TAX_PREP_HOURS']
        delete assignedList['TAX_PREP_BUDGET']
        data = data.map(({TAX_PREP_BVSA, ...data}) => data)
    }

    if (checkColumnObj['TAX_REVIEW_HOURS'] === 0) {
        delete assignedList['TAX_REVIEW_HOURS']
        delete assignedList['TAX_REVIEWER_BUDGET']
        data = data.map(({TAX_REVIEW_BVSA, ...data}) => data)
    }

    if (props.filter === 'all') {
        data = data
    } else if (props.filter.type === 'aging') {
        data = data
            .filter(o => {
            return o.PROJECT_AGING === props.filter.filter
            })
    } else if (props.filter.type === 'bvsa') {
        let filter = ''
        switch (props.filter.filter) {
            case 'Under Budget':
                filter = 'GREEN'
                break;
            case '75% Complete':
                filter = 'YELLOW'
                break;
            case 'Over Budget':
                filter = 'RED'
                break;
        }
        data = data 
            .filter(o => {
                return o.TAX_PREP_BVSA === filter || o.TAX_REVIEW_BVSA === filter || o.CAAS_ACCT_BVSA === filter || o.CAAS_REVIEW_BVSA === filter || o.CAAS_PREP_BVSA === filter
            })
    }

    return (
        <Fragment>
            <h3>Assigned to me</h3>
            <DataViewTable data={data} columnList={assignedList} scrollHeight={props.scrollHeight} onChange={dummyFunc} />
        </Fragment>
    )
}
