import { useSelector } from "react-redux";
import { useState, useEffect, Fragment } from "react";
import { SyncLoader } from "react-spinners";
import { RingGuage } from "../../../components/visualizations/charts/RingCharts";


// import { Splitter, SplitterPanel } from 'primereact/splitter';
// import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
    
import "primeicons/primeicons.css"
import "/node_modules/primeflex/primeflex.css"
import "../../../style/mywip.css"

// const USDollar = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });


function CyPyMetricGauge(props) {
  const className = `${props.name}-metric-guage`;
  const p1_color = props.colors ? props.colors[0] : null;
  const p2_color = props.colors ? props.colors[1] : null;

  return (
    <div className={className}>
      <RingGuage
        min_value={props.min_value}
        max_value={props.max_value}
        colors={{ primaryColor: p1_color, secondaryColor: p2_color }}
        py={{
          data: props.py,
          color: props.colors,
        }}
        cy={{
          data: props.cy,
          color: props.colors,
        }}
        angle={props.angle}
        label={props.label}
      />
    </div>
  );
}


function UtilizationMetricCard(props){
    // const user = useSelector(state => state.user);

    return (
            // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
            // <div className={`flex w-5 xlg:px-6 flex-wrap justify-content-evenly ${props.classTags}`}>
            <div className="flex flex-wrap justify-content-center xlg:px-6">
                <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
                
                <InternalCard 
                    label="Realization" 
                    value={props.data.REALIZATION}
                    comparator= {props.comparatorData ? props.comparatorData.REALIZATION : null}
                    formater={(value) => value+'%'}
                    title={props.comparatorFlag !== 'py' ? 'Difference between your PY and CY Realization (YTD)' : 'No Calculation'}
                />
                <InternalCard 
                    label="Utilization" 
                    comparator= {props.comparatorData ? props.comparatorData.UTILIZATION : null}
                    value={props.data.UTILIZATION} 
                    formater={(value) => value+'%'}
                    title={props.comparatorFlag !== 'py' ? 'Difference between your PY and CY Utilization (YTD)' : 'No Calculation'}
                />
                <InternalCard 
                    label="Total Hours"
                    comparator= {props.comparatorData ? props.comparatorData.WIP_HOURS : null}
                    value={props.data.WIP_HOURS}
                    formater={(value) => new Intl.NumberFormat().format(value)}
                    title={props.comparatorFlag !== 'py' ? 'Difference between your PY and CY Total Hours (YTD)' : 'No Calculation'}
                />
                <InternalCard 
                    label="Billable Hours" 
                    value={props.data.BILLABLE_HOURS}
                    comparator= {props.comparatorData ? props.comparatorData.BILLABLE_HOURS : null}
                    formater={(value) => `${new Intl.NumberFormat().format(value)}`}
                    title={props.comparatorFlag !== 'py' ? 'Difference between your PY and CY Billable Hours (YTD)' : 'No Calculation'}
                />
                {/* <InternalCard 

                    label="Non-Billable Hours" 
                    value={props.data.NON_BILLABLE_HOURS}
                    comparator= {props.comparatorData ? props.comparatorData.NON_BILLABLE_HOURS : null}
                    formater={(value) => `${new Intl.NumberFormat().format(value)} Hrs`}
                /> */}
    </div>
  );
}

const InternalCard = (cardData) => {

    // console.log(cardData.value)
    const footer = () => {
        const makeComparison = (value) => {
            const calcValue = cardData.inverted ? (value - cardData.value) : (cardData.value - value);
            const dispValue = cardData.formater(calcValue.toFixed(2));
            // console.log(calcValue);
            const color = Math.sign(calcValue) === 1 ? "text-green-500 pi pi-arrow-up" : Math.sign(calcValue) === 0 ? "pi": "text-red-500 pi pi-arrow-down" ;
            return <span title={cardData.title} className={`${color}`}> {dispValue}</span>
        }
        // console.log(cardData.comparator)
        // console.log(cardData.value ? 'yep': 'nope')
        let foot = cardData.comparator !== null && cardData.value !== null ? makeComparison(cardData.comparator) : <span title={cardData.title} className="pi pi-ellipsis-h"></span>;
        // console.log(foot)
        return foot;
    };
    // const cardDisp = cardData.formater(cardData.value);
    // console.log(cardDisp, cardData.value);
    return ( 
        <Card 
            className="mywip-metric-card col-4 my-1 mx-2 pt-0 text-2xl transparent font-bold" 
            title={cardData.label}
            pt={{ title: {className:"text-sm"}}}
            footer={footer}
        >
            {/* {cardData.value ? cardData.formater(cardData.value) : 'No Goal'} */}
            {cardData.formater(cardData.value)}
        </Card>
    )
};

// function RealizationMetricCard(props){
    
//     // console.log(props)
//     return (
//             // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
//             // <div className={`flex w-5 pl-3 pr-3 flex-wrap justify-content-around ${props.classTags}`}>
//             <div>
//                 <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
//                 {/* <InternalCard 
//                     label="WIP Amount" 
//                     value={props.data.BILLABLE_WIP_AMOUNT}
//                     comparator= {props.comparatorData ? props.comparatorData.BILLABLE_WIP_AMOUNT : null}
//                     formater={(value) => USDollar.format(value)}
//                 /> */}
//                 {/* <InternalCard 
//                     label="WIP Billed" 
//                     value={props.data.WIPBILLED}
//                     comparator= {props.comparatorData ? props.comparatorData.WIPBILLED : null}
//                     formater={(value) => USDollar.format(value)} 
//                 /> */}
//                 {/* <InternalCard 
//                     label="Effective Rate" 
//                     value={props.data.EFF_RATE}
//                     comparator= {props.comparatorData ? props.comparatorData.EFF_RATE : null}
//                     formater={(value) => USDollar.format(value)}
//                 /> */}
//                 <InternalCard 
//                     label="Realization" 
//                     value={props.data.REALIZATION}
//                     comparator= {props.comparatorData ? props.comparatorData.REALIZATION : null}
//                     formater={(value) => value+'%'}
//                 />
//             </div>
//     )
// }

function GoalCards ({type, data, classTags}) {
    const user = useSelector(state => state.user)
    return (
        <div className={`flex w-6 pl-3 pr-3 flex-wrap justify-content-around ${classTags}`} style={{paddingTop: 10}}>
            <InternalCard 
                label={`Monthly Goal ${type} Realization for Level`}
                // value={data ? data[`${type}_REALIZATION`] : null}
                value={user.GOAL_REALIZATION}
                comparator= {data.REALIZATION}
                inverted={true}
                formater={(value) => value+'%'}
                title="Difference between your Realization and your Goal Realization"
            />
            <InternalCard 
                label={`Monthly Goal ${type} Utilization for Level`}
                value={type === 'PY' ? user.PY_LEVEL_UTIL_GOAL : user.LEVEL_UTIL_GOAL}
                comparator={data.UTILIZATION}
                formater={(value) => value+'%'}
                inverted={true}
                title="Difference between your Utilization and your Goal Utilization"
            />
            <InternalCard 
                label={`Monthly Goal ${type} Total Hours for Level`}
                value={type === 'PY' ? user.PY_LEVEL_TOTAL_GOAL : user.LEVEL_TOTAL_GOAL}
                comparator={data.WIP_HOURS}
                formater={(value) => new Intl.NumberFormat().format(value)}
                inverted={true}
                title="Difference between your Total Hours and your Goal Total Hours"
            />
            <InternalCard 
                label={`Monthly Goal ${type} Billable Hours for Level`}
                value={type === 'PY' ? user.PY_LEVEL_BILLABLE_GOAL : user.LEVEL_BILLABLE_GOAL}
                comparator={data.BILLABLE_HOURS}
                formater={(value) => new Intl.NumberFormat().format(value)}
                inverted={true}
                title="Difference between your Billable Hours and your Goal Billable Hours"
            />

            {/* <InternalCard 
                label={`AVG ${type} Effective Rate for Level`}
                value={data[`${type}_EFF_RATE`]}
                comparator= {comparatorData ? comparatorData.BILLABLE_WIP_AMOUNT : null}
                formater={(value) => USDollar.format(value)}
            /> */}
        </div>
    )
};

function AverageCards ({type, data, comparatorData}) {
    // const user = useSelector(state => state.user)
    
    return (
        <div className={`flex flex-wrap justify-content-around`}>
            <InternalCard 
                label={`Average Realization for Level`}
                value={data ? data[`${type}_REALIZATION`] : null}
                comparator= {comparatorData.REALIZATION}
                formater={(value) => value+'%'}
                inverted={true}
                title="Difference between your Realization and average Realization for your level (CY)"
            />
            <InternalCard 
                label={`Average Utilization for Level`}
                value={data ? data[`${type}_UTILIZATION`] : null}
                comparator= {comparatorData.UTILIZATION}
                formater={(value) => value+'%'}
                inverted={true}
                title="Difference between your Utilization and average Utilization for your level (CY)"
            />
            <InternalCard 
                label={`Average Total Hours for Level`}
                value={data ? data[`${type}_WIP_HOURS`] : null}
                comparator= {comparatorData.WIP_HOURS}
                formater={(value) => new Intl.NumberFormat().format(value)}
                inverted={true}
                title="Difference between your Total Hours and average Total Hours for your level (CY)"
            />
            <InternalCard 
                label={`Average Billable Hours for Level`}
                value={data ? data[`${type}_BILLABLE_HOURS`] : null}
                comparator= {comparatorData.BILLABLE_HOURS}
                formater={(value) => new Intl.NumberFormat().format(value)}
                inverted={true}
                title="Difference between your Billable Hours and average Billable Hours for your level (CY)"
            />
        </div>
    )
};


export function MyWIP({ classes }) {
  // document.title = `Magnify | My WIP`

  const metricTemplate = {
    WIP_HOURS: 0,
    BILLABLE_HOURS: 0,
    NON_BILLABLE_HOURS: 0,
    BILLABLE_WIP_AMOUNT: 0,
    REALIZATION: 0,
    UTILIZATION: 0,
    EFF_RATE: 0,
  };

  // const [pyMetrics, setPyMetrics] = useState(metricTemplate);
  // const [cyMetrics, setCyMetrics] = useState(metricTemplate);
  const [metricsData, setMetricsData] = useState(undefined);
  const [levelAVG, setLevelAVG] = useState(undefined);


    const user = useSelector(state => state.user.STAFF_KEY);
    const authToken = useSelector(state => state.user.bearer);
    const level = useSelector(state => state.user);


  const metricsEndpoint = `https://mag-stewart.azurewebsites.net/api/user/${user}/wip/metrics`;
  const levelAVGEndpoint = `https://mag-stewart.azurewebsites.net/api/user/${user}/wip/?level=${user}`;

  // const metricsEndpoint = `https://magnify-apis.azurewebsites.net/api/user/1567/wip/metrics`;
  // const levelAVGEndpoint = `https://magnify-apis.azurewebsites.net/api/user/1567/wip/?level=1567`;


    // let pyLoaded = false
    // let cyLoaded = false

    useEffect(()=>{
        const getMetrics = async (query) => {
            const resp = await fetch(query,  
                {method: "GET",  mode: "cors", headers:{authorization: authToken} 
            });
            return resp.json()
        };

        getMetrics(metricsEndpoint).then((data) => {setMetricsData(data[0])});
        getMetrics(levelAVGEndpoint).then((data) => {setLevelAVG(data[0])});
        // setCyMetrics({BILLABLE_HOURS: metricsData.CY_BILLABLE_HOURS})
    }, [setMetricsData, metricsEndpoint, authToken, levelAVGEndpoint]);

  // let py = pyMetrics;
  // let cy = cyMetrics;
  // console.log(metricsData, levelAVG)

  let py = {};
  let cy = {};

  if (metricsData && levelAVG) {
    Object.keys(metricTemplate).forEach((key) => {
      // console.log(key)
      py[key] = metricsData[`PY_${key}`];
      cy[key] = metricsData[`CY_${key}`];
    });
  }

  let pyMetrics = py;
  let cyMetrics = cy;

  const angle = 270;
  const primaryColor = "#1490b4";
  const secondaryColor = "#356091";


    if (metricsData) {
        return (
            
            <div className={`content-panel flex flex-wrap justify-content-center ${classes}`}>
                {/* <div className="gauge-panel h-17rem xl:w-10 flex justify-content-around"> */}
                <div className="gauge-panel xl:w-12 flex flex-wrap justify-content-around">
                        <CyPyMetricGauge 
                            py={metricsData.PY_UTILIZATION}
                            cy={metricsData.CY_UTILIZATION}
                            colors={[primaryColor, secondaryColor]}
                            label="Utilization"
                            name="utilization"
                            angle={angle}
                        />
                        <CyPyMetricGauge 
                            py={metricsData.PY_REALIZATION}
                            cy={metricsData.CY_REALIZATION}
                            max_value="150"
                            colors={[primaryColor, secondaryColor]}
                            label="Realization"
                            name="realization"
                            angle={angle}
                        />
                </div>
                <div className="data-panel w-12 flex flex-wrap justify-content-center">
                    
                    <div className={`flex w-6 xlg:px-6 flex-wrap justify-content-evenly border-right bottom-border-dot`}>
                        <div className="w-full flex justify-content-center"> 
                        </div>
                            <UtilizationMetricCard 
                                label="Prior Year Hours and Utilization" 
                                data={pyMetrics}
                                location="end"
                                classTags="border-right"
                                comparatorFlag='py'
                            />
                            {/* <RealizationMetricCard 

                                // label="Prior Year Effective Rate and Realization" 
                                data={pyMetrics}
                                location="end"
                                classTags="border-right"
                            />  */}

                    </div>
                    <div className={`flex w-6 xlg:px-6 flex-wrap justify-content-evenly bottom-border-dot`}>
                            <UtilizationMetricCard 
                                label="Current Year Hours and Utilization" 
                                data={cyMetrics}
                                location="start"
                                comparatorData={pyMetrics}
                                comparatorFlag='cy'
                            />
                            {/* <RealizationMetricCard 

                                // label="Current Year Effective Rate and Realization" 
                                data={cyMetrics}
                                location="start"
                                comparatorData={pyMetrics}
                            /> */}

                    </div>
                        {level.LEVEL_CODE === 'ZZZZ' ? <Fragment></Fragment> : <Fragment><GoalCards type="PY" data={pyMetrics} classTags="border-right" util={pyMetrics.UTILIZATION} /><GoalCards type="CY" data={cyMetrics} util={cyMetrics.UTILIZATION} /></Fragment>}
                    {
                        level.LEVEL_CODE === 'ZZZZ' ?
                        <Fragment></Fragment> :
                        <div className="top-border-bold">
                            <AverageCards
                                type="CY"
                                data={levelAVG}
                                comparatorData={cyMetrics}
                            />
                        </div>
                    }
                </div>
            </div>
            
       )
    } else {
        return (
            <div className={`utilization-gauge primary-bg flex justify-content-center ${classes}`}>
                <h1>Syncing Data<SyncLoader color={primaryColor} cssOverride={{textAlign:"center"}}/></h1>
            </div>
        )
    }

}

