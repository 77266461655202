import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode /*, FilterOperator*/ } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
// import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider'
// import { InputNumber } from 'primereact/inputnumber';
import { ExportExcel } from '../../utils/exporters'

import DefaultDataTable from 'react-data-table-component';
import { Fragment, /*useEffect,*/ useState, /*useCallback,*/ useRef } from 'react';
import { useSelector } from 'react-redux';
import "../../style/ar-tables.css"
// import { current } from '@reduxjs/toolkit';

export function DefaultTable(props) {

	const {columns, selector} = props
	const data = useSelector(state => state.data.data[selector])

	return (
		<DefaultDataTable columns={columns} data={data} striped/>
	)
}

export function DataViewTable({data, onChange, columnList, scrollHeight, customColumn, tableStyle, tablePt}) { 

	const [ dataTable, setDataTable ] = useState(data);
	const currentData = useRef(dataTable)
	const dt = useRef(null);

	const [filters, setFilters] = useState({
        CLIENT: { value: null, matchMode: FilterMatchMode.IN },
        CLIENTNAME: { value: null, matchMode: FilterMatchMode.IN },
		CODE: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_PARTNER: { value: null, matchMode: FilterMatchMode.IN },
		CLIENT_MANAGER: { value: null, matchMode: FilterMatchMode.IN },
        OFFICE: { value: null, matchMode: FilterMatchMode.IN },
        ENGAGEMENTTYPE: { value: null, matchMode: FilterMatchMode.IN },
        DELIVERABLES: { value: null, matchMode: FilterMatchMode.IN },
        CURRENTSTEP: { value: null, matchMode: FilterMatchMode.IN },
        WORKFLOW_STATUS: { value: null, matchMode: FilterMatchMode.IN },
        PIC: { value: null, matchMode: FilterMatchMode.IN },
        ASSIGNEDTO: { value: null, matchMode: FilterMatchMode.IN },
        AGING_PERIOD: { value: null, matchMode: FilterMatchMode.IN },
        SERVICETYPE: { value: null, matchMode: FilterMatchMode.IN },
        PROJECT_AGING: { value: null, matchMode: FilterMatchMode.IN },
        OUTSTANDING: { value: null, matchMode: FilterMatchMode.BETWEEN }})
	
	// useEffect(()=>{
	// 	onChange(dataTable);
	// }, [onChange, dataTable])
	

	// const filterData = useCallback((value) => {
	// 		// setDataTable(value)
	// 		onChange(dataTable);
	// }, [dataTable, onChange])

	const outstandingTemplate = (value) => {
		return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	}

	const dateTemplate = (value) => {
		const date = new Date(value)

		return date.toLocaleDateString();
	}

    const daysAtStepTemplate = value => {
        return +value.toFixed(0)
    }

	const bvsaTemplate = metric => {
		let bvsaClass = ''
		switch (metric.color) {
			case 'RED':
				bvsaClass = 'bvsa-red'
				break;
			case 'YELLOW':
				bvsaClass = 'bvsa-yellow'
				break;
			case 'GREEN':
				bvsaClass = 'bvsa-green'
				break;
		}

		return <div className={bvsaClass}>{metric.value}</div>
	}

	const multiselectTemplate = (options) => {
		// console.log("The options outputs: ", options)
		let optionsList = [];
		let placeholder = "";
		switch (options.field) {
			case "OFFICE":
				optionsList = columnList['OFFICE'];
				placeholder = "Office";
				break;
			case "AGING_PERIOD":
				placeholder = "Aging Period"
				optionsList = columnList['AGING_PERIOD'];
				break;
			default:
				break;
		};
		return (
			<MultiSelect
				filter
				value={options.value}
				options={optionsList}
				className="column-filter" 
				onChange={(e) => options.filterApplyCallback(e.value)}
				display="chip"
				placeholder={placeholder}
				showSelectAll="false"
			/>
		)
	}
	
	const multiFilterTemplate = (options) => {
		// console.log(options);
		let optionsList = [];
		let placeholder = "";
		switch (options.field) {
			case "CLIENT":
				optionsList = columnList['CLIENT'];
				placeholder = "Client";
				break;
			case "CLIENTNAME":
				optionsList = columnList['CLIENTNAME'];
				placeholder = "Client";
				break;
			case "CLIENT_MANAGER":
				optionsList = columnList['CLIENT_MANAGER'];
				placeholder = "Client Manager";
				break;
			case "CLIENT_PARTNER":
				optionsList = columnList['CLIENT_PARTNER'];
				placeholder = "Partner"
				break;
			case "CODE":
				optionsList = columnList['CODE'];
				placeholder = "Code"
				break;
			case "CLIENTNUMBER":
				optionsList = columnList['CLIENTNUMBER'];
				placeholder = "Code"
				break;
			case "ENGAGEMENTTYPE":
				optionsList = columnList['ENGAGEMENTTYPE'];
				placeholder = "Engagement Type"
				break;
			case "ASSIGNEDTO":
				optionsList = columnList['ASSIGNEDTO'];
				placeholder = "Assigned To"
				break;
			case "PIC":
				optionsList = columnList['PIC'];
				placeholder = "Person in Charge"
				break;
			case "CURRENTSTEP":
				optionsList = columnList['CURRENTSTEP'];
				placeholder = "Current Step"
				break;
			case "WORKFLOW_STATUS":
				optionsList = columnList['WORKFLOW_STATUS'];
				placeholder = "Workflow Status"
				break;
			case "DELIVERABLES":
				optionsList = columnList['DELIVERABLES'];
				placeholder = "Deliverable"
				break;
			case "SERVICETYPE":
				optionsList = columnList['SERVICETYPE'];
				placeholder = "Service Type"
				break;
            case "DAYS_AT_STEP":
                optionsList = columnList['DAYS_AT_STEP'];
                placeholder = "Days at Step"
                break;
            default:
				break;
		};
		return (
			<span>
			<MultiSelect 
				value={options.value}
				options={optionsList}
				onChange={(e) => options.filterApplyCallback(e.value)}
				className="lg-column-filter column-filter w-full"
				placeholder={placeholder}
				display="chip"
				filter
			/>
			</span>
		)
	}

	
	const sliderFilterTemplate = (options) => {
		return (
			<Fragment>
				<Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
				<div className="flex align-items-center justify-content-between px-2">
					<span>{options.value ? options.value[0] : 0}</span>
					<span>{options.value ? options.value[1] : 100}</span>
				</div>
			</Fragment>
		)
	};

	// const moneyRangeFilterTemplate = (options) => {
	// 	return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
	// };
	
	// const clientBodyTemplate = (rowData) => {
	// 	return (
	// 	<span>{rowData.CLIENT}</span>
	// 	)
	// };

	const saveSheet = () => {
		ExportExcel(currentData.current);
	};

	const paginatorRight = () => {
		return <Button type="button" icon="pi pi-download" onClick={saveSheet} data-pr-tooltip="XLS" text />;
	};

	function *renderSwitch(list){
		for (let item in list) {
			switch (item) {
				case "CLIENT":
					yield (
						<Column 
							key="CLIENT"
							className="lg-column column"
							header="Client" 
							field="CLIENT" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='CLIENT' 
							filterMatchMode="in"
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "CLIENTNAME":
					yield (
						<Column 
							key="CLIENTNAME"
							className="lg-column column"
							header="Client" 
							field="CLIENTNAME" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='CLIENTNAME' 
							filterMatchMode="in"
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
					case "CODE":
							yield (
								<Column 
									key="CODE"
									className="sm-column column"
									header="Code" 
									field="CODE" 
									sortable 
									showFilterMenu={false} 
									filterClear="false" 
									filter
									filterField='CODE'
									filterElement={multiFilterTemplate}
								></Column>
								);
								break;
					case "CLIENTNUMBER":
							yield (
								<Column 
									key="CLIENTNUMBER"
									className="sm-column column"
									header="Code" 
									field="CLIENTNUMBER" 
									sortable 
									showFilterMenu={false} 
									filterClear="false" 
									filter
									filterField='CLIENTNUMBER'
									filterElement={multiFilterTemplate}
								></Column>
								);
								break;
		
				case "CLIENT_MANAGER":
					yield (
						<Column 
							key="CLIENT_MANAGER"
							className="md-column column"
							header="Client Manager" 
							field="CLIENT_MANAGER" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='CLIENT_MANAGER' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "CLIENT_PARTNER":
					yield (
							<Column 
								key="CLIENT_PARTNER"
								className="md-column column"
								field="CLIENT_PARTNER" 
								header="Partner" sortable 
								showFilterMenu={false} 
								filterClear="false" 
								filter
								filterField='CLIENT_PARTNER' 
								filterElement={multiFilterTemplate}>
							</Column>
							);
					break;
				case "OFFICE":
					yield (
						<Column
							key="OFFICE"
							className="sm-column column" 
							field="OFFICE" 
							header="Office" sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='OFFICE'
							filterMatchMode="in"
							filterElement={multiselectTemplate}>
						</Column>
						);
						break;
				case "AGING_PERIOD":
					yield (
						<Column
							key="AGING_PERIOD"
							className="sm-column column" 
							field="AGING_PERIOD" 
							header="Aging Period" sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='AGING_PERIOD'
							filterMatchMode="in"
							filterElement={multiselectTemplate}>
						</Column>
						);
						break;
				case "OUTSTANDING":
					yield (
						<Column 
							key="OUTSTANDING"
							className="md-column column"
							field="OUTSTANDING"
							filterField="OUTSTANDING"
							header="Outstanding" 
							sortable body={outstandingTemplate}>
						</Column>
						);
					break;
				case "WIPOUTSTANDING":
					yield (
						<Column 
							key="OUTSTANDING"
							className="md-column column"
							dataType="numeric"
							field="OUTSTANDING"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="OUTSTANDING"
							filterElement={sliderFilterTemplate}
							header="Outstanding" 
							sortable 
							body={value => outstandingTemplate(value.WIPOUTSTANDING)}>
						</Column>
						);
					break;
				case "PIC":
					yield (
						<Column 
							key="PIC"
							className="lg-column column"
							header="PiC" 
							field="PIC" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='PIC' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "ASSIGNEDTO":
					yield (
						<Column 
							key="ASSIGNEDTO"
							className="md-column column"
							header="Assigned To" 
							field="ASSIGNEDTO" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='ASSIGNEDTO' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "ENGAGEMENTTYPE":
					yield (
						<Column 
							key="ENGAGEMENTTYPE"
							className="lg-column column"
							header="Engagement" 
							field="ENGAGEMENTTYPE" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='ENGAGEMENTTYPE' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "DELIVERABLES":
					yield (
						<Column 
							key="DELIVERABLES"
							className="md-column column"
							header="Deliverable" 
							field="DELIVERABLES" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='DELIVERABLES' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "CURRENTSTEP":
					yield (
						<Column 
							key="CURRENTSTEP"
							className="md-column column"
							header="Current Step" 
							field="CURRENTSTEP" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='CURRENTSTEP' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "WORKFLOW_STATUS":
					yield (
						<Column 
							key="WORKFLOW_STATUS"
							className="md-column column"
							header="Status" 
							field="WORKFLOW_STATUS" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='WORKFLOW_STATUS' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "SERVICETYPE":
					yield (
						<Column 
							key="SERVICETYPE"
							className="md-column column"
							header="Service Type" 
							field="SERVICETYPE" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='SERVICETYPE' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "PROJECT_AGING":
					yield (
						<Column 
							key="PROJECT_AGING"
							className="lg-column column"
							header="Project Aging" 
							field="PROJECT_AGING" 
							sortable 
							showFilterMenu={false} 
							filterClear="false" 
							filter 
							filterField='PROJECT_AGING' 
							filterElement={multiFilterTemplate}
						></Column>
						);
						break;
				case "ASSIGNEDON":
					yield (
						<Column 
							key="ASSIGNEDON"
							className="sm-column column"
							dataType="date"
							field="ASSIGNEDON"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="ASSIGNEDON"
							filterElement={sliderFilterTemplate}
							header="Assigned" 
							sortable 
							body={value => dateTemplate(value.ASSIGNEDON)}>
						</Column>
						);
					break;
				case "INTERNAL_DUE_DATE":
					yield (
						<Column 
							key="INTERNAL_DUE_DATE"
							className="sm-column column"
							dataType="date"
							field="INTERNAL_DUE_DATE"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="INTERNAL_DUE_DATE"
							filterElement={sliderFilterTemplate}
							header="Internal Due Date" 
							sortable 
							body={value => dateTemplate(value.INTERNAL_DUE_DATE)}>
						</Column>
						);
					break;
				case "CURRENTDUEDATE":
					yield (
						<Column 
							key="CURRENTDUEDATE"
							className="md-column column"
							dataType="date"
							field="CURRENTDUEDATE"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CURRENTDUEDATE"
							filterElement={sliderFilterTemplate}
							header="Due" 
							sortable 
							body={value => dateTemplate(value.CURRENTDUEDATE)}>
						</Column>
						);
					break;

				//FIX FOR DAYS AT STEP
				case "DAYS_AT_STEP":
					yield (
						<Column 
							key="DAYS_AT_STEP"
							className="sm-column column w-2"
							dataType="numeric"
							field="DAYS_AT_STEP"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="DAYS_AT_STEP"
							filterElement={sliderFilterTemplate}
							header="Days at Step" 
							sortable 
							body={value => daysAtStepTemplate(value.DAYS_AT_STEP)}
						>
						</Column>
						);
					break;
				case "TAX_PREP_HOURS":
					yield (
						<Column 
							key="TAX_PREP_HOURS"
							className="sm-column column"
							dataType="numeric"
							field="TAX_PREP_HOURS"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="TAX_PREP_HOURS"
							filterElement={sliderFilterTemplate}
							header="Tax Prep Hours" 
							sortable
							body={rowData => bvsaTemplate({color: rowData.TAX_PREP_BVSA, value: rowData.TAX_PREP_HOURS})}
						>
						</Column>
						);
					break;
				case "TAX_REVIEW_HOURS":
					yield (
						<Column 
							key="TAX_REVIEW_HOURS"
							className="sm-column column"
							dataType="numeric"
							field="TAX_REVIEW_HOURS"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="TAX_REVIEW_HOURS"
							filterElement={sliderFilterTemplate}
							header="Tax Review Hours" 
							sortable
							body={rowData => bvsaTemplate({color: rowData.TAX_REVIEW_BVSA, value: rowData.TAX_REVIEW_HOURS})}
						>
						</Column>
						);
					break;
				case "CAAS_ACCT_HOURS":
					yield (
						<Column 
							key="CAAS_ACCT_HOURS"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_ACCT_HOURS"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_ACCT_HOURS"
							filterElement={sliderFilterTemplate}
							header="CAAS Accounting Hours" 
							sortable
							body={rowData => bvsaTemplate({color: rowData.CAAS_ACCT_BVSA, value: rowData.CAAS_ACCT_HOURS})}
						>
						</Column>
						);
					break;
				case "CAAS_PREP_HOURS":
					yield (
						<Column 
							key="CAAS_PREP_HOURS"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_PREP_HOURS"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_PREP_HOURS"
							filterElement={sliderFilterTemplate}
							header="CAAS Prep Hours" 
							sortable
							body={rowData => bvsaTemplate({color: rowData.CAAS_PREP_BVSA, value: rowData.CAAS_PREP_HOURS})}
						>
						</Column>
						);
					break;
				case "CAAS_REVIEW_HOURS":
					yield (
						<Column 
							key="CAAS_REVIEW_HOURS"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_REVIEW_HOURS"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_REVIEW_HOURS"
							filterElement={sliderFilterTemplate}
							header="CAAS Review Hours" 
							sortable
							body={rowData => bvsaTemplate({color: rowData.CAAS_REVIEW_BVSA, value: rowData.CAAS_REVIEW_HOURS})}
						>
						</Column>
						);
					break;
				case "TAX_PREP_BUDGET":
					yield (
						<Column 
							key="TAX_PREP_BUDGET"
							className="sm-column column"
							dataType="numeric"
							field="TAX_PREP_BUDGET"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="TAX_PREP_BUDGET"
							filterElement={sliderFilterTemplate}
							header="Tax Prep Budget" 
							sortable
							// body={rowData => bvsaTemplate({color: rowData.TAX_PREP_BVSA, value: rowData.TAX_PREP_BUDGET})}
						>
						</Column>
						);
					break;
				case "TAX_REVIEWER_BUDGET":
					yield (
						<Column 
							key="TAX_REVIEWER_BUDGET"
							className="sm-column column"
							dataType="numeric"
							field="TAX_REVIEWER_BUDGET"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="TAX_REVIEWER_BUDGET"
							filterElement={sliderFilterTemplate}
							header="Tax Review Budget" 
							sortable
							// body={rowData => bvsaTemplate({color: rowData.TAX_REVIEW_BVSA, value: rowData.TAX_REVIEWER_BUDGET})}
						>
						</Column>
						);
					break;
				case "CAAS_ACCT_BUDGET":
					yield (
						<Column 
							key="CAAS_ACCT_BUDGET"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_ACCT_BUDGET"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_ACCT_BUDGET"
							filterElement={sliderFilterTemplate}
							header="CAAS Accounting Budget" 
							sortable
							// body={rowData => bvsaTemplate({color: rowData.CAAS_ACCT_BVSA, value: rowData.CAAS_ACCT_BUDGET})}
						>
						</Column>
						);
					break;
				case "CAAS_PREP_BUDGET":
					yield (
						<Column 
							key="CAAS_PREP_BUDGET"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_PREP_BUDGET"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_PREP_BUDGET"
							filterElement={sliderFilterTemplate}
							header="CAAS Prep Budget" 
							sortable
							// body={rowData => bvsaTemplate({color: rowData.CAAS_PREP_BVSA, value: rowData.CAAS_PREP_BUDGET})}
						>
						</Column>
						);
					break;
				case "CAAS_REVIEWER_BUDGET":
					yield (
						<Column 
							key="CAAS_REVIEWER_BUDGET"
							className="sm-column column"
							dataType="numeric"
							field="CAAS_REVIEWER_BUDGET"
							// filter
							showFilterMatchModes={false}
							showFilterMenu={true} 
							filterClear="false" 
							filterField="CAAS_REVIEWER_BUDGET"
							filterElement={sliderFilterTemplate}
							header="CAAS Review Budget" 
							sortable
							// body={rowData => bvsaTemplate({color: rowData.CAAS_REVIEW_BVSA, value: rowData.CAAS_REVIEWER_BUDGET})}
						>
						</Column>
						);
					break;
				default:
					break;	
				} 
			}
		};
		
	return (
		<section className="data-view-table">
			<DataTable
					ref={dt}
					columnResizeMode="expand" resizableColumns
					onValueChange={filteredData => {
						onChange(filteredData);
						currentData.current = filteredData;
					}}

					filters={filters}
					onFilter={(e) => setFilters(e.filters)}
					
					value={data} 
					// tableStyle={{ minWidth: '50rem' }}
					showGridlines 
					stripedRows 
					sortMode="multiple" 
					filterDisplay="row"
					paginator rows={25} 
					rowsPerPageOptions={[5, 10, 25, 50, 100]}
					paginatorClassName='paginator-footer' 
					dataKey="id" 
					emptyMessage="No Clients Found"
					scrollable
					scrollHeight={scrollHeight}
					// size='small'
					currentPageReportTemplate="{first} to {last} of {totalRecords}"
					paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
					paginatorRight={paginatorRight}
				>
			{[...renderSwitch(columnList)]}
			{customColumn}

			</DataTable>
		</section>
	)
};
