import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
      data: {}
  },
  reducers: {
    // setAuthentication: (state, auth) => {
    //     state.authenticated = auth.payload
    // },
    // setUser: (state, user) => {
    //     // const newState = {
    //     //     authenticated: true, 
    //     //     ...user.payload
    //     // }

    //       return user.payload
    // },
    addData: (state, action) => {
        const { objKey, data} = action.payload

        if (!state.data[objKey]) {
            state.data[objKey] = {}
        }

        state.data[objKey] = data
    }
  }
})

// Action creators are generated for each case reducer function
export const { addData } = dataSlice.actions

export default dataSlice.reducer