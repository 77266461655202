import { useSelector } from 'react-redux'

function CreateBubbleTag(props) {
    return (
    <span className="chart-bubble-tag" style={{background: props.color}}></span>
    )
}

export default function Cards(props) {
    // console.log("card value :", props)
    // const reduxCardValue = useSelector(state => state.data.data[props.level][props.metric]); 
    const cardValue = props.cardValue ? props.cardValue : 0;
    
 
    // let title = ''
    // let target = {}
    // let formattedValue = ''

    // switch (props.metric) {
    //     case 'UNPAID_INVOICE':
    //         title = 'Outstanding'
    //         target = {targetValue: 2000000, targetTitle: 'Target < $2M', targetOper: 'less'}
    //         formattedValue = `$${cardValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    //         break;
    //     case 'PERCENT_CURR':
    //         title = '% Current'
    //         target = {targetValue: 75, targetTitle: 'Target > 75%', targetOper: 'great'}
    //         formattedValue = `${cardValue.toFixed(2)}%`
    //         break;
    //     case 'PERCENT_31_60':
    //         title = '% 31-60 days'
    //         target = {targetValue: 15, targetTitle: 'Target < 15%', targetOper: 'less'}
    //         formattedValue = `${cardValue.toFixed(2)}%`
    //         break;
    //     case 'PERCENT_61_90':
    //         title = '% 61-90 days'
    //         target = {targetValue: 10, targetTitle: 'Target < 10%', targetOper: 'less'}
    //         formattedValue = `${cardValue.toFixed(2)}%`
    //         break;
    //     case 'PERCENT_OVERDUE':
    //         title = '% over 90 days'
    //         target = {targetValue: 5, targetTitle: 'Target < 5%', targetOper: 'less'}
    //         formattedValue = `${cardValue.toFixed(2)}%`
    //         break;
    //     default:
    //         title = 'Unknown metric'
    // }
        return (
            <article className='metric'>
                <p className="card-title">{props.title}</p>
                <p className="card-value">{props.color ? <CreateBubbleTag color={props.color}/> : ""}{props.value}</p>
            </article>
        )


//     if (props.level.includes('firm')) {
//         let color = {color: 'red'}
//         if (target.targetOper === 'less') {
//             color.color = cardValue < target.targetValue ? 'green' : 'red'
//         } else {
//             color.color = cardValue > target.targetValue ? 'green' : 'red'
//         }
        

//         return (
//             <article className='metric'>
//                 <p className="card-title">{title}</p>
//                 <p className="card-value" style={color}>{props.color ? <CreateBubbleTag color={props.color}/> : ""}{formattedValue}</p>
//                 <p className="card-target">{target.targetTitle}</p>
//             </article>
//         )
//     } else {
//         return (
//             <article className='metric'>
//                 <p className="card-title">{title}</p>
//                 <p className="card-value">{props.color ? <CreateBubbleTag color={props.color}/> : ""}{formattedValue}</p>
//             </article>
//         )
//     }
    
}