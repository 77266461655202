import { useState, useEffect, Fragment } from "react"
import { useSelector } from 'react-redux';

import FF from "./FF";
import "../../../style/mywip.css"
// import { FFAgingChart } from "../../../components/visualizations/charts/PieCharts";
import { BvsAFF, StaticFF } from "../../../components/report_fragments/StaticMetrics";

export default function MyFF() {

    const [assignedData, setAssignedData] = useState([]);
    const [filter, setFilter] = useState('all')
    // const [picData, setPicData] = useState([]);

    const user = useSelector(state => state.user.EMPLOYEE);
    const authToken = useSelector(state => state.user.bearer);
    // const authToken = "VconLOFECvrqXmj/htmLeZ3y9scGYCKcBcUOsaff+4B4jV26GK4OvqUB23NDQPgn21jODpsiU8f68D/y51yA6ayczoC/PWW4xYoIq70r2Yhas4Nux67Ny9SRw6HAynId+FVV2wkTWQ4PmUdY9YQyzTC4EK0wOQzkmuo0YekqEdVGUKv1hUZQe4rA2XvQOI9dMyHztFN4T3LuOc/zJQGXH5pA29E6CUtYLo5zkCz7yQmMEO/IV4eIXjhfLuDu4wCxCpZDvRQ+r9okAgR+OVnW+XvRtg5/CRyJ+CQCp0a7IvckZuBGGDq1MrBiS8irgGyNwKDeQ55OMpTbTisPWRNmBw==";

    useEffect(()=>{
        fetch(`https://mag-stewart.azurewebsites.net/api/user/${user.toUpperCase().replace(' ', '_')}/ff/assigned`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }).then(res => {
            if (!res.ok) {
                throw new Error('Uh oh! There was an unexpected error!')
            } else {
                return res.json()
            }
        }).then(data => {
            data.forEach(obj => {
                delete Object.assign(obj, {id: obj.ID + ' - assigned'}).ID
            })
            setAssignedData(data)
            // setUserData(data[0])
        }).catch((err) => {
            console.error(err)
        });
    
        // fetch(`https://mag-stewart.azurewebsites.net/api/user/${user.toUpperCase().replace(' ', '_')}/ff/pic`, {
        //     method: "GET",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         "Authorization": authToken
        //     }
        // }).then(res => {
        //     if (!res.ok) {
        //         throw new Error('Uh oh! There was an unexpected error!')
        //     } else {
        //         return res.json()
        //     }
        // }).then(data => {
        //     data.forEach(obj => {
        //         delete Object.assign(obj, {id: obj.ID + ' - pic'}).ID
        //     })
        //     setPicData(data)
        //     // setUserData(data[0])
        // }).catch((err) => {
        //     console.error(err)
        // })
    }, [user, authToken, setAssignedData]);

    return (
            <section className="ff-layout">
                {assignedData.length > 0 ?
                    (<Fragment>
                        <article className="ff-charts">
                            <StaticFF assignedData={assignedData} filter={filter} setFilter={setFilter} />
                            <div className="divider"></div>
                            <BvsAFF assignedData={assignedData} filter={filter} setFilter={setFilter} />
                        </article>
                        <FF type="assigned" data={assignedData} scrollHeight='55vh' filter={filter} />
                    </Fragment>) :
                    <br></br>
                }
            </section>
    )
}