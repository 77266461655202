import ReactEChart from 'echarts-for-react';



export function UnpaidARChart (chartData) {

	if (chartData.chartData) {
	// const totalOutstanding = chartData.chartData.OUTSTANDING;
	const percentCurr = chartData.chartData.PERCENT_CURR === 0 ? 0 :  chartData.chartData.PERCENT_CURR;
	const percent31 = chartData.chartData.PERCENT_31_60;
	const percent61 = chartData.chartData.PERCENT_61_90;
	const percentOverdue = chartData.chartData.PERCENT_OVERDUE;

	const pieChartOptions = {
		tooltip: {
		trigger: 'item'
		},
		graphic: {
			elements: [{
			  type: 'text',
			  left: 'center',
			  top: 'middle',
			  z: 999,
			  style: {
				text: chartData.centerLabel,
				textAlign: 'center',
				fontSize: 26,
			  }
			}]
		  },
		series: [
		{
			name: 'Unpaid AR',
			type: 'pie',
			radius: ['55%', '95%'],
			avoidLabelOverlap: true,
			startAngle: 180,
			label: {
			show: false,
			position: 'center'
			},
			emphasis: {
				label: {
					position: 'inside',
					show: true,
					fontSize: 20,
					fontWeight: 'bold',
		  
					formatter(param) {
						// correct the percentage
						return param.name + ' (' + param.percent + '%)';
					},
					itemStyle: {
						z: 999,
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					  }
				}
			},
			data: [
			{ value: percentCurr, name: 'Current'},
			{ value: percent31, name: '31-60 Days', itemStyle: {}},
			{ value: percent61, name: '61-90 Days', itemStyle: {}},
			{ value: percentOverdue, name: 'Overdue', itemStyle: {}},
			],
		}
		]
	}
	return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)
  }
}

export function FFAgingChart (chartData) {

	const events = {
        'click': params => {
            if (params.name === chartData.filter.filter) {
                chartData.setFilter('all')
            } else {
                chartData.setFilter({type: 'aging', filter: params.name})
            }
        }
    }

	if (chartData.chartData.length > 0) {
		
		const AGING_GROUPS = Object.groupBy(chartData.chartData, ({PROJECT_AGING}) => {
			let aging_label = ''
			switch (PROJECT_AGING) {
				case 'Current':
					aging_label = 'DAYS_0_15'
					break;
				case '16-30 Days':
					aging_label = 'DAYS_16_30'
					break;
				case '31+ Days':
					aging_label = 'DAYS_31_PLUS'
					break;
				// case '61-90 Days':
				// 	aging_label = 'DAYS_61_90'
				// 	break;
				// case '91-120 Days':
				// 	aging_label = 'DAYS_91_120'
				// 	break;
				default:
					aging_label = PROJECT_AGING.toUpperCase()
			}

			return aging_label
		})

		// const TOTAL = chartData.chartData.length
		const DAYS_0_15 = 'DAYS_0_15' in AGING_GROUPS ? AGING_GROUPS.DAYS_0_15.length : 0
		const DAYS_16_30 = 'DAYS_16_30' in AGING_GROUPS ? AGING_GROUPS.DAYS_16_30.length : 0
		const DAYS_31_PLUS = 'DAYS_31_PLUS' in AGING_GROUPS ? AGING_GROUPS.DAYS_31_PLUS.length : 0
		// const DAYS_31_60 = 'DAYS_31_60' in AGING_GROUPS? AGING_GROUPS.DAYS_31_60.length : 0
		// const DAYS_61_90 = 'DAYS_61_90' in AGING_GROUPS ? AGING_GROUPS.DAYS_61_90.length : 0
		// const DAYS_91_120 = 'DAYS_91_120' in AGING_GROUPS ? AGING_GROUPS.DAYS_91_120.length : 0
		// const OVERDUE = 'OVERDUE' in AGING_GROUPS ? AGING_GROUPS.OVERDUE.length : 0
		const EXTENDED = 'EXTENDED' in AGING_GROUPS ? AGING_GROUPS.EXTENDED.length : 0

	const pieChartOptions = {
		tooltip: {
		trigger: 'item'
		},
		graphic: {
			elements: [{
			  type: 'text',
			  left: 'center',
			  top: 'center',
			  z: 999,
			  style: {
				text: '',
				textAlign: 'center',
				fontSize: 20,
				textShadow: "-1px 1px 0 #000"
			  }
			}]
		  },
		series: [
		{
			name: 'Projects by Aging Status',
			type: 'pie',
			radius: ['55%', '95%'],
			avoidLabelOverlap: true,
			startAngle: 180,
			label: {
			show: false,
			position: 'center'
			},
			emphasis: {
				label: {
					position: 'inside',
					show: false,
					fontSize: 20,
					fontWeight: 'bold',
		  
					// formatter(param) {
					// 	// correct the percentage
					// 	return param.name + ' (' + param.percent + '%)';
					// },
					itemStyle: {
						z: 999,
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)',
					  }
				}
			},
			data: [
			{ value: DAYS_0_15, name: 'Current', itemStyle: {color: '#40B27D'}},
			{ value: DAYS_16_30, name: '16-30 Days', itemStyle: {color: '#F4CA5E'}},
            { value: DAYS_31_PLUS, name: '31+ Days', itemStyle: {color: '#FC8451'}},
			// { value: DAYS_31_60, name: '31-60 Days', itemStyle: {color: '#F4CA5E'}},
			// { value: DAYS_61_90, name: '61-90 Days', itemStyle: {color: '#5C7BD9'}},
			// { value: DAYS_91_120, name: '91-120 Days', itemStyle: {color: '#FC8451'}},
			// { value: OVERDUE, name: 'Overdue', itemStyle: {color: '#E66B68'}},
			{ value: EXTENDED, name: 'Extended', itemStyle: {color: '#7ED2F4'}},
			],
		}
		]
	}
	return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '275px', width: '275px'}} onEvents={events} />)
  }
}

export function FFBvsAChart (chartData) {

	const events = {
        'click': params => {
            if (params.name === chartData.filter.filter) {
                chartData.setFilter('all')
            } else {
                chartData.setFilter({type: 'bvsa', filter: params.name})
            }
        }
    }

	const pieChartOptions = {
		tooltip: {
		trigger: 'item'
		},
		graphic: {
			elements: [{
			  type: 'text',
			  left: 'center',
			  top: 'center',
			  z: 999,
			  style: {
				text: '',
				textAlign: 'center',
				fontSize: 20,
			  }
			}]
		  },
		series: [
		{
			name: 'Projects by BvsA',
			type: 'pie',
			radius: ['55%', '95%'],
			avoidLabelOverlap: true,
			startAngle: 180,
			label: {
			show: false,
			position: 'center'
			},
			emphasis: {
				label: {
					position: 'inside',
					show: false,
					fontSize: 20,
					fontWeight: 'bold',
		  
					// formatter(param) {
					// 	// correct the percentage
					// 	return param.name + ' (' + param.percent + '%)';
					// },
					itemStyle: {
						z: 999,
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					  }
				}
			},
			data: [
			{ value: chartData.chartData.green.size, name: 'Under Budget', itemStyle: {color: '#006100'}},
			{ value: chartData.chartData.yellow.size, name: '75% Complete', itemStyle: {color: '#ffeb9c'}},
			{ value: chartData.chartData.red.size, name: 'Over Budget', itemStyle: {color: '#9c0006'}}
			],
		}
		]
	}
	return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '275px', width: '275px'}} onEvents={events} />)

}

// export function RingGuageUtilization (props) {

// }