import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: false,
      EMPLOYEE_NUMBER: '',
      EMPLOYEE_EMPID: '',
      OFFICE: '',
      STAFF_EMAIL: '',
      BVSA: '',
      STAFFINDEX: 0,
      EMPLOYEE: '',
      TOP_AR: 0,
      TOP_WIP: 0,
      TOP_BILL: 0,
      TOP_HR: 0,
      DEPT_AR: 0,
      DEPT_WIP: 0,
      DEPT_BILL: 0,
      DEPT_HR: 0,
      TEAM_WIP: 0,
      TEAM_HR: 0,
      RESPONSIBLE_AR: 0,
      RESPONSIBLE_WIP: 0,
      RESPONSIBLE_BILL: 0,
      SELF_WIP: 1,
      SELF_HR: 1,
      LEVEL: '',
      DEPARTMENT: '',
      HOMEROOM: '',
      bearer: "VconLOFECvrqXmj/htmLeZ3y9scGYCKcBcUOsaff+4B4jV26GK4OvqUB23NDQPgn21jODpsiU8f68D/y51yA6ayczoC/PWW4xYoIq70r2Yhas4Nux67Ny9SRw6HAynId+FVV2wkTWQ4PmUdY9YQyzTC4EK0wOQzkmuo0YekqEdVGUKv1hUZQe4rA2XvQOI9dMyHztFN4T3LuOc/zJQGXH5pA29E6CUtYLo5zkCz7yQmMEO/IV4eIXjhfLuDu4wCxCpZDvRQ+r9okAgR+OVnW+XvRtg5/CRyJ+CQCp0a7IvckZuBGGDq1MrBiS8irgGyNwKDeQ55OMpTbTisPWRNmBw=="
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setUser: (state, user) => {
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    reset: () => {
      return {
        authenticated: 'pending',
        EMPLOYEE_NUMBER: '',
        EMPLOYEE_EMPID: '',
        OFFICE: '',
        STAFF_EMAIL: '',
        BVSA: '',
        STAFFINDEX: 0,
        EMPLOYEE: '',
        TOP_AR: 0,
        TOP_WIP: 0,
        TOP_BILL: 0,
        TOP_HR: 0,
        DEPT_AR: 0,
        DEPT_WIP: 0,
        DEPT_BILL: 0,
        DEPT_HR: 0,
        TEAM_WIP: 0,
        TEAM_HR: 0,
        RESPONSIBLE_AR: 0,
        RESPONSIBLE_WIP: 0,
        RESPONSIBLE_BILL: 0,
        SELF_WIP: 1,
        SELF_HR: 1,
        LEVEL: '',
        DEPARTMENT: '',
        HOMEROOM: '',
        bearer: "VconLOFECvrqXmj/htmLeZ3y9scGYCKcBcUOsaff+4B4jV26GK4OvqUB23NDQPgn21jODpsiU8f68D/y51yA6ayczoC/PWW4xYoIq70r2Yhas4Nux67Ny9SRw6HAynId+FVV2wkTWQ4PmUdY9YQyzTC4EK0wOQzkmuo0YekqEdVGUKv1hUZQe4rA2XvQOI9dMyHztFN4T3LuOc/zJQGXH5pA29E6CUtYLo5zkCz7yQmMEO/IV4eIXjhfLuDu4wCxCpZDvRQ+r9okAgR+OVnW+XvRtg5/CRyJ+CQCp0a7IvckZuBGGDq1MrBiS8irgGyNwKDeQ55OMpTbTisPWRNmBw=="
    }
    },
    clearUser: () => {
    //   console.log('here')
    //   console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        // sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, reset } = loginSlice.actions

export default loginSlice.reducer